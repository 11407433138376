import { Button } from "@mui/material";

import * as React from "react";
import { orange } from "../utils/colors";

export const CTA = ({ title, link }) => {
  return (
    <div
      style={{ backgroundColor: orange, color: "white", padding: "10px 20px", cursor: "pointer", fontSize: 16, fontWeight: "bold" }}
      variant="contained"
      color="info"
      onClick={() => {
        if (link) window?.open(link);
      }}
    >
      {title}
    </div>
  );
};
